
  .airplane {
    background-image: url('imgs/airplane.png'); /* Update path to your airplane icon */
  }
  .airplane.diag {
    background-image: url('imgs/airplane.png'); /* Update path to your airplane icon */

  }
  .airplane.diag {
    rotate: 270deg;

  }
  
  
  .helicopter {

    background-image: url('imgs/helicopter.png'); /* Update path to your helicopter icon */
  }
  

  @keyframes flyAirplaneDiagonal {
    0% { transform: translate(-100vw, 100vh); }
    100% { transform: translate(100vw, -100vh); }
  }
  
  @keyframes flyAirplane {
    0% { transform: translate(-100vw, 100vh); }
    100% { transform: translate(100vw, -100vh); }
  }
  
  @keyframes flyHelicopter {
    0% { transform: translateY(100vh); }
    100% { transform: translateY(-100vh); }
  }
  
  .airplane, .helicopter {
    position: absolute;
    width: 60px;
    height: 60px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.4;
  }
  

  /* Airplanes moving from bottom left to top right */
  .airplane1 {
    top: 10%;
    left: -100px;
    animation: flyAirplane 20s linear infinite;
  }
  
  .airplane2 {
    top: 30%;
    left: -150px;
    animation: flyAirplane 25s linear infinite;
  }
  
  .airplane3 {
    top: 50%;
    left: -200px;
    animation: flyAirplane 30s linear infinite;
  }
  
  .airplane4 {
    top: 70%;
    left: -250px;
    animation: flyAirplane 35s linear infinite;
  }
  
  .airplane5 {
    top: 90%;
    left: -300px;
    animation: flyAirplane 40s linear infinite;
  }
  
  /* Airplanes moving from bottom right to top left */
  .airplane6 {
    bottom: 10%;
    right: -100px;
    animation: flyAirplaneDiagonal 22s linear infinite;
  }
  
  .airplane7 {
    bottom: 30%;
    right: -150px;
    animation: flyAirplaneDiagonal 27s linear infinite;
  }
  
  .airplane8 {
    bottom: 50%;
    right: -200px;
    animation: flyAirplaneDiagonal 32s linear infinite;
  }
  
  .airplane9 {
    bottom: 70%;
    right: -250px;
    animation: flyAirplaneDiagonal 37s linear infinite;
  }
  
  .airplane10 {
    bottom: 90%;
    right: -300px;
    animation: flyAirplaneDiagonal 42s linear infinite;
  }
  
  /* Helicopters moving from bottom to top */
  .helicopter1 {
    bottom: -60px;
    left: 10%;
    animation: flyHelicopter 25s linear infinite;
  }
  
  .helicopter2 {
    bottom: -80px;
    left: 30%;
    animation: flyHelicopter 30s linear infinite;
  }
  
  .helicopter3 {
    bottom: -100px;
    left: 50%;
    animation: flyHelicopter 35s linear infinite;
  }
  
  .helicopter4 {
    bottom: -120px;
    left: 70%;
    animation: flyHelicopter 40s linear infinite;
  }
  
  .helicopter5 {
    bottom: -140px;
    left: 90%;
    animation: flyHelicopter 45s linear infinite;
  }
  